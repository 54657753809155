import { css } from "@emotion/css";

import Logo from '../assets/images/logo.svg';
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { mobile, tablet } from "../assets/css/breakpoints";
import MenuIconOpen from "../assets/images/icons/menu-open.png";
import MenuIconClosed from "../assets/images/icons/menu-open.png";

const header = css({
    padding: '20px 40px',
    color: 'white',
    height: 90,
    width: '100%',
    position: 'fixed',
    transition: 'top 2s ease 0s',
    top: -90,
    zIndex: 99,
    backgroundColor: 'transparent',
    boxSizing: 'border-box',

    '&.active': {
        top: 0,
    },

    a: {
        fontFamily: 'Inter',
        fontSize: 16,
        fontStyle: 'normal',
        fontWeight: 400,
        textDecorationLine: 'none',
        color: 'white',
        textTransform: 'uppercase',
        display: 'flex',

        '&:hover': {
            background: '-webkit-linear-gradient(bottom left, #6775B5, #AEB9F5, #F0EEFF)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            transition: 'all .5s ease-out',
        }
    },

    img: {
        maxWidth: 101
    },

    '.wrapper': {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignContent: 'center',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',

        '.mobile-menu': {
            display: 'none',
        },

        ul: {
            listStyle: 'none',
            padding: 0,
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            alignContent: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 55,

            li: {
                a: {

                }
            }
        }
    },
    [tablet]: {
        padding: 30,
        '.wrapper': {

            '.mobile-menu': {
                display: 'block',
                cursor: 'pointer',
            },

            ul: {
                gap: 60,
                top: 80,
                left: '-100%',
                width: '100%',
                height: '100%',
                backgroundColor: 'black',
                flexDirection: 'column',
                flexWrap: 'nowrap',
                alignItems: 'flex-start',
                justifyContent: 'center',
                display: 'flex',
                position: 'fixed',
                margin: 0,
                padding: '0px 30px 100px 30px',
                transition: 'left 1s ease 0s',
                boxSizing: 'border-box',
                zIndex: 99,


                '&.active': {
                    left: 0,
                },

                li: {
                    a: {
                        fontFamily: 'Unbounded',
                        fontSize: 60,
                        fontStyle: 'normal',
                        fontWeight: 400,
                    }
                }
            }
        }
    },

    [mobile]: {
        padding: 20,
        '.wrapper': {

            '.mobile-menu': {
                display: 'block',
                cursor: 'pointer',
            },

            ul: {
                top: 60,
                padding: '0px 20px 80px 20px',
                li: {
                    a: {
                        fontSize: 40,
                    }
                }
            }
        }
    },
})

export default function Header() {
    const [isOpen, setIsOpen] = useState(false);
    const [showHeader, setShowHeader] = useState(false);

    useEffect(() => {
        const scrollHide = () => setShowHeader(
            (window.scrollY > 300 || isOpen || (window as any).location.pathname !== '/')
                ? true
                : false
        );

        window.onscroll = () => scrollHide();
    }, [isOpen]);

    return (
        <header className={`${header} ${(showHeader || isOpen) ? 'active' : ''}`}>
            <div className={'wrapper'}>
                <Link to="/" onClick={() => setIsOpen(false)}>
                    <img src={Logo} className="logo" alt={'sokail'} />
                </Link>
                <span className="mobile-menu" onClick={() => setIsOpen(!isOpen)}>
                    <img src={!isOpen ? MenuIconClosed : MenuIconOpen} alt="Open menu" />
                </span>
                <ul className={`${isOpen ? 'active' : ''}`}>
                    <li>
                        <Link to="/services" onClick={() => setIsOpen(false)}>services</Link>
                    </li>
                    <li>
                        <Link to="/contact" onClick={() => setIsOpen(false)}>contact</Link>
                    </li>
                </ul>
            </div>
        </header>
    );
}