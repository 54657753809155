import { css } from "@emotion/css";
import { ILocation } from "../interfaces/_main";
import { useSwiper } from "swiper/react";
import { mobile, tablet } from "../assets/css/breakpoints";

const location = (image: string) => css({
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '90vh',
    padding: 30,

    h4: {
        fontFamily: 'Unbounded',
        fontSize: 54,
        fontStyle: 'normal',
        fontWeight: 400,
        margin: 0,
        paddingBottom: 10
    },
    span: {
        fontFamily: 'Inter',
        fontSize: 25,
        fontStyle: 'normal',
        fontWeight: 300,
        color: 'white'
    },

    [tablet]: {
        h4: {
            fontSize: 40,
        }
    },
    [mobile]: {
        h4: {
            fontSize: 30,
        }
    }
})

export default function Location({ image, title, subtitle }: ILocation) {
    const swiper = useSwiper();
    return (
        <div className={location(image)}>
            <h4>{title}</h4>
            <span>{subtitle}</span>
        </div>
    );
}