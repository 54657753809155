import { css } from "@emotion/css";


const container = css({
    backgroundColor: 'black',

    'p, h1, h2, h3, h4, a, li': {
        color: 'white'
    },

    'input:not([type="file" i], [type="image" i], [type="checkbox" i], [type="radio" i])': {
        width: '100%',
        color: '#8E8D8D',
        fontFamily: 'Outfit',
        fontSize: 16,
        fontStyle: 'normal',
        fontWeight: 300,
        padding: '20px',
        borderRadius: 13,
        border: '1px solid #4d4d4d',
        backgroundColor: 'transparent',
    }
})

export {
    container
}