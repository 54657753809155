import { css } from "@emotion/css";
import { laptop, mobile, tablet } from "../assets/css/breakpoints";

const project = css({
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: 120,
    marginBottom: 20,

    p: {
        fontFamily: 'Inter',
        fontSize: 19,
        fontStyle: 'normal',
        fontWeight: 300,
        margin: 0,
        textAlign: 'left',
        maxWidth: 600,
    },
    h4: {
        fontFamily: 'Unbounded',
        fontSize: 70,
        fontStyle: 'normal',
        fontWeight: 300,
        margin: 0,
        textAlign: 'left',
        marginBottom: 0,
        maxWidth: 550,
    },
    '.thumb': {
        borderRadius: 30,
        width: '100%',
        height: '100%',
        marginBottom: 60,
        outline: '4px solid black',
        outlineOffset: '-2px',
    },
    '.content': {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignContent: 'center',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        gap: 60,
        marginBottom: 60
    },

    ul: {
        listStyle: 'none',
        margin: 0,
        padding: 0,
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        width: '100%',

        li: {
            fontFamily: 'Unbounded',
            fontSize: 38,
            fontStyle: 'normal',
            fontWeight: 300,
            marginBottom: 10,
            textAlign: 'left',
            background: '-webkit-linear-gradient(327deg, #ffffff , #AEB9F5 70%, #ffffff)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            border: '1px solid',
            borderImageSlice: 1,
            borderImageSource: 'linear-gradient(to left, #ffffff00, #ffffff)',
            borderLeft: 0,
            borderRight: 0,
            borderTop: 0,
            paddingTop: 10,
            paddingBottom: 10,
            flex: '48%',
            width: '100%',
            marginRight: 10,

            '&:nth-child(3n), &:nth-child(4n)': {
                borderBottom: '0',
                paddingTop: 0,
            }
        },
    },
    [laptop]: {
        marginBottom: 30,
        padding: 60,

        h4: {
            fontSize: 54,
        },
        ul: {
            li: {
                fontSize: 30
            }
        }
    },
    [tablet]: {
        marginBottom: 60,
        padding: 30,
        h4: {
            fontSize: 50,
            maxWidth: '100%',
        },
        p: {
            maxWidth: '100%',
        },
        '.content': {
            flexDirection: 'column',
            alignItems: 'stretch',
        },
        ul: {
            li: {
                fontSize: 30
            }
        }
    },
    [mobile]: {
        marginBottom: 70,
        padding: 20,
        h4: {
            fontSize: 40,
        },
        p: {
            fontSize: 16,
        },
        ul: {
            li: {
                flex: '100%',
                width: '100%',
                borderBottom: '1px solid !important',

                '&:last-child': {
                    border: '0 !important',
                }
            },
        },
    }
})

export default function Service({ image, title, description, items }: { image: string, title: string, description: string, items: Array<string> }) {
    const descriptionHTML = { __html: description };

    return (
        <div className={project}>
            <img className="thumb" alt={title} src={image} />
            <div className="content">
                <div>
                    <h4>{title}</h4>
                </div>
                <div>
                    <p dangerouslySetInnerHTML={descriptionHTML}></p>
                </div>
            </div>
            <ul>
                {items.map(item => <li>― {item}</li>)}
            </ul>
        </div>
    );
}