import { css } from "@emotion/css";
import BGServices from '../../assets/images/services/bg-services.jpg';
import BGServices2 from '../../assets/images/services/bg-2-services.jpg';
import { mobile, tablet } from "../../assets/css/breakpoints";

const services = css({
    'p, h1, h2, h3, h4, a, li': {
        color: 'white'
    },
})

const closing = css({
    backgroundImage: `url(${BGServices2});`,
    backgroundPosition: 'bottom',
    height: '100vh',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    alignContent: 'center',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 90,
    padding: 60,

    h2: {
        textAlign: 'center',
        fontFamily: 'Unbounded',
        fontSize: '87px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        maxWidth: 700,
        margin: 0,
        background: '-webkit-linear-gradient(327deg, #ffffff , #AEB9F5 70%, #ffffff)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
    },

    [tablet]: {
        padding: 30,
        height: '70vh',
        marginTop: 60,

        h2: {
            fontSize: 50,
        },
    },
    [mobile]: {
        padding: 20,

        h2: {
            fontSize: 40,
        },
    }
})

const intro = css({
    backgroundImage: `url(${BGServices});`,
    backgroundPosition: 'center',
    height: '100vh',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',

    ':after': {
        position: 'absolute',
        bottom: '-60px',
        content: "''",
        background: 'linear-gradient(to bottom, transparent 0%, black 80%)',
        width: '100%',
        height: 400,
    },
    ':before': {
        position: 'absolute',
        top: 0,
        content: "''",
        background: 'linear-gradient(to top, rgb(0 0 0 / 30%) 0%, rgb(0 0 0 / 30%) 90%)',
        width: '100%',
        height: '100%',
    },

    '.wrapper': {
        maxWidth: 734,
        padding: 60,
        zIndex: 1,

        [mobile]: {
            padding: 0,
    
            // h2: {
            //     fontSize: 40,
            // },
        },

        h1: {
            fontFamily: 'Unbounded',
            fontSize: 84.784,
            fontStyle: 'normal',
            fontWeight: 400,
            textAlign: 'left',
            margin: 0,
            background: '-webkit-linear-gradient(327deg, #ffffff , #AEB9F5 70%, #ffffff)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
        },

        p: {
            fontFamily: 'Inter',
            fontSize: 19,
            fontStyle: 'normal',
            fontWeight: 300,
            textAlign: 'left',
            maxWidth: 443,
            marginLeft: 'auto',
            marginTop: 43,
        }
    },
    [tablet]: {
        '.wrapper': {
            maxWidth: '100%',
            padding: 30,

            h1: {
                fontSize: 50,
            },
        },
    },
    [mobile]: {
        '.wrapper': {
            maxWidth: '100%',
            padding: 20,

            h1: {
                fontSize: 40,
            },
        },
    }
})

const items = css({

})

export {
    services,
    intro,
    items,
    closing
}
