import { css } from "@emotion/css";
import { IReason } from "../interfaces/_main";
import { laptop, mobile, tablet } from "../assets/css/breakpoints";

const reason = css({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    gap: 30,
    backgroundColor: 'black',
    height: 400,

    p: {
        fontFamily: 'Inter',
        fontSize: 19,
        fontStyle: 'normal',
        fontWeight: 300,
        margin: 0,
        textAlign: 'left',
    },
    h4: {
        fontFamily: 'Unbounded',
        fontSize: 47.445,
        fontStyle: 'normal',
        fontWeight: 300,
        lineHeight: 'normal',
        textAlign: 'left',
        marginTop: 0,
        marginBottom: 40,
    },
    img: {
        borderRadius: 34,
        width: '100%',
        maxWidth: 444
    },
    '.info': {
        display: 'flex',
        flexWrap: 'wrap',
        alignContent: 'center',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        marginLeft: 50,
    },

    [tablet]: {
        display: 'flex',
        flexWrap: 'nowrap',
        alignContent: 'center',
        justifyContent: 'flex-end',
        alignItems: 'center',
        flexDirection: 'column-reverse',
        height: 600,
        '.info': {
            marginLeft: 0,
        },
    },
    [mobile]: {
        h4: {
            fontSize: 38
        }
    }
})

export default function Reason({ image, title, description }: IReason) {
    return (
        <div className={reason}>
            <div className="info">
                <h4>{title}</h4>
                <p>{description}</p>
            </div>
            <img alt={title} src={image} />
        </div>
    );
}