import { css } from "@emotion/css";

const button = css({
    position: 'relative',
    width: 'max-content',
    height: 'max-content',
    zIndex: 1,

    '&:before': {
        content: '""',
        position: 'absolute',
        top: '-2px',
        bottom: '-2px',
        left: '-2px',
        right: '-2px',
        background: 'linear-gradient(to bottom right, #B5F0ED, #FFFFFF, #4663AC)',
        borderRadius: 30,
    },
    button: {
        borderRadius: 30,
        color: 'white',
        backgroundColor: 'black',
        cursor: 'pointer',
        display: 'flex',
        padding: '10px 24px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 9,
        position: 'relative',

        '&:hover': {
            background: 'linear-gradient(to bottom right, #A3B4C0, #4F5C70)',
        },
        span: {
            color: 'white',
            fontFamily: 'Inter',
            fontSize: 14,
            fontStyle: 'normal',
            fontWeight: 500,
            textWrap: 'nowrap',
            position: 'relative',
        },
        img: {
            width: 'auto',
        }
    }
})

export default function Button({ onClick, text, icon, style }: { onClick: Function, text: string, icon?: any, style?: any }) {
    return (
        <div className={button} style={style ? style : {}} >
            <button onClick={(e) => onClick(e)}>
                <span>{text}</span>
                {icon ? <img src={icon} alt={''} height={14} /> : null}
            </button>
        </div>
    );
}