import { Fragment, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSwiper, SwiperSlide, Swiper } from 'swiper/react';
import { Autoplay, EffectFade, Navigation, Mousewheel } from 'swiper';

// Import Swiper styles
import "swiper/css";

import Project from '../../components/project';
import Reason from '../../components/reason';
import Location from '../../components/location';

import * as styles from './home.styles';
import 'swiper/css/effect-fade';

import ArrowRight from '../../assets/images/icons/arrow-right.svg';
import ArrowDown2 from '../../assets/images/icons/arrow-down.svg';
import ArrowDownload from '../../assets/images/icons/arrow-download.svg';

import Logo from '../../assets/images/logo.svg';

import PPImage from '../../assets/images/home/projects/pp.jpg';
import PradaImage from '../../assets/images/home/projects/prada.jpg';
import PreliosImage from '../../assets/images/home/projects/prelios.jpg';
import SMCImage from '../../assets/images/home/projects/smc.jpg';
import SPCImage from '../../assets/images/home/projects/spc.jpg';
import VersaceImage from '../../assets/images/home/projects/versace.jpg';
import JustImage from '../../assets/images/home/projects/just.jpg';
import PLSTCImage from '../../assets/images/home/projects/plstc.jpg';
import VittorioImage from '../../assets/images/home/projects/vittorio.jpg';
import GuggenheimImage from '../../assets/images/home/projects/guggenheim.jpg';
import RenovationImage from '../../assets/images/home/projects/renovation.jpg';

import DJImage from '../../assets/images/home/why/dj.jpg';
import AIImage from '../../assets/images/home/why/ai.png';
import MetaImage from '../../assets/images/home/why/meta.png';
import MusicImage from '../../assets/images/home/why/music.png';
import PioneersImage from '../../assets/images/home/why/pioneers.png';

import EmanuleImage from '../../assets/images/home/book/emanule.jpg';
import ConversoImage from '../../assets/images/home/book/converso.jpg';
import LabirintoImage from '../../assets/images/home/book/labirinto.jpg';
import CalcioneImage from '../../assets/images/home/book/calcione.jpg';
import CastelDelMonteImage from '../../assets/images/home/book/castel-del-monte.jpg';
import ChiesaImage from '../../assets/images/home/book/chiesa.jpg';
import ViscontiImage from '../../assets/images/home/book/visconti.jpg';

import ExcluBG from '../../assets/images/home/exclusive/bg.jpg'
import PartnerUpBG from '../../assets/images/home/partner-up-bg.jpg'
import PortfolioBG from '../../assets/images/home/portfolio-bg.jpg'
import ServicesBG from '../../assets/images/home/services-bottom-bg.jpg'

import VideoPortfolio from '../../assets/videos/bg_portfolio.mp4';
import VideoExclusive from '../../assets/videos/bg_exclusive.mp4';
import VideoHero from '../../assets/videos/bg_hero.mp4';
import { ILocation, IProject, IReason } from '../../interfaces/_main';
import Button from '../../components/button';

import { Unity, useUnityContext } from "react-unity-webgl";


// @ts-ignore
const images = require.context('../../assets/images/home/logos', false, /\.(png|jpe?g|svg)$/)
const imageList = images.keys().map((image: any) => images(image));

function Into() {
  const navigate = useNavigate();

  return (
    <div className={styles.intro}>
      <video className="background-video" autoPlay loop muted>
        <source src={VideoHero} type="video/mp4" />
      </video>
      <div className="section-left">
        <img src={Logo} className="logo" alt={'sokail'} />
        <div className="menu">
          <Link to="/services">services</Link>
          <Link to="/contact">contact</Link>
        </div>
      </div>
      <div className="section-right">
        <div className="wrapper">
          <h3>turning visions<br/>into experiences </h3>
          <p>
            Sokail is a full-service creative agency that brings <b>extraordinary experiences, brands and products to life.</b>
          </p>
          <Button onClick={() => navigate('/services')} text={'Check out what we do'} icon={ArrowRight} />
        </div>
      </div>
    </div>
  );
}

function Into2() {
  const navigate = useNavigate();

  return (
    <div className={styles.intro2}>
      <h2>Impact Globally</h2>
      <p>
        We elevate market-defining brands through comprehensive creative solutions to empower their growth, make great impact and bring magic into the world.
      </p>
      <p>
        We have built a global portfolio of extraordinary experiences for more than a decade, but the main idea stays always the same: make all the ideas real.
      </p>
      <span className='space'></span>
      <Button onClick={() => navigate('/contact')} text={'Let us take you next level'} icon={ArrowRight} />
    </div>
  );
}

function Partners() {
  return (
    <div className={styles.partners}>
      <div className="section-top">
        <h3>Creating experiences that go beyond the usual & take you next level.</h3>
      </div>
      <div className="section-bottom">
        <h3>Partnering with</h3>
        <div className="partners">
          {imageList.map((img: string, key: number) =>
            <img key={key} src={img} alt="" className='partner' />)}
        </div>
      </div>
    </div>
  );
}

function Services() {
  const navigate = useNavigate();
  


  const { unityProvider, loadingProgression, isLoaded } = useUnityContext({
    loaderUrl: "../../Build/SokailServices.loader.js",
    dataUrl: "../../Build/SokailServices.data",
    frameworkUrl: "../../Build/SokailServices.framework.js",
    codeUrl: "Build/SokailServices.wasm",
  });

  return (

    <div className={styles.services}>
    <div className="title-wrapper">
      <h4>Services</h4>
    </div>

    <Fragment>
      {!isLoaded && (
        <p>Loading Application... {Math.round(loadingProgression * 100)}%</p>
      )}
      <Unity
        unityProvider={unityProvider}
        style={{ visibility: isLoaded ? "visible" : "hidden", width: 1920, height: 1080 }}
        className = {'unity-container'}
      />
    </Fragment>
  </div>
  );
}

function Projects() {
  const [showMore, setShowMore] = useState(false);
  const projects = [
    {
      title: 'VERSACE',
      description: 'We had the pleasure of working with Versace on several projects over the years, that include full range of creative services.',
      image: VersaceImage
    },
    {
      title: 'PRELIOS',
      description: `Prelios is the Pirelli real estate department.
                    As their media content creator for four years, we were responsible for developing all of their visual materials,
                    including images, drawings and 3D renderings.`,
      image: PreliosImage
    },
    {
      title: 'PHILIPP PLEIN',
      description: `For Philipp Plein’s Urban Jungle after party during Milan Fashion Week in 2012,
                    we were responsible for the complete music setup, PR and entertainment.`,
      image: PPImage
    },
    {
      title: 'PUNKS WEAR PRADA',
      description: `Punks Wear Prada is a well known party night in Milan.
                    PWP is dedicated to fashion junkies, influencers, models,
                    hipsters & freaks who love to get dressed up & vogue on the dancefloor to pop & electronic sounds.`,
      image: PradaImage
    },
    // {
    //   title: 'SOCIAL MUSIC CITY',
    //   description: `Social Music City is a music festival in Italy that gathers the biggest names from the electronic music scene:
    //                 Tale of Us, Marco Carola, Richie Hawtin, Charlotte de Witte, Chris Liebing, Enrico Sangiuliano,
    //                 Anfisa Letyago, Pisetzky and many more. The festival was sold in 2017. `,
    //   image: SMCImage
    // },
    // {
    //   title: 'San Patrignano Community',
    //   description: `San Patrignano Community is the biggest addiction recovery community in Italy.
    //                 Our work included Media and Marketing activities of the whole community.`,
    //   image: SPCImage
    // },
    // {
    //   title: 'Vittorio Grigolo',
    //   description: `We had a pleasure to be in charge of music production for the latest upcoming album for Vittorio Grigolo,
    //                 world famous lyrical singer.
    //                 The album we worked on was recorded in Japan.`,
    //   image: VittorioImage
    // },
    {
      title: 'Just This Record Label',
      description: `Just This is the expression of a social, metropolitan and underground movement we are living and going through all together.
                    Just This is run by the duo Hunter/Game & Pisetzky from 12 Years,
                    and had the pleasure to release some of the best artists In the music industry.`,
      image: JustImage
    },
    {
      title: 'PLSTC at Art Basel Miami',
      description: `During the second Art Basel in Miami,
                    PLSTC event presented to its public installations and photos that gravitate around the plastic theme,
                    all soundtracked by DJ and live sets. The event was organized by Pisetzky,
                    in collaboration & partnership with Natuzzi.`,
      image: PLSTCImage
    },

  ] as Array<IProject>
  const moreProjects = [
    {
      title: 'Just This: Guggenheim',
      description: `Had the pleasure to be invited to organize Just This event at Guggenheim Bilbao Museum.
                    The event hosted only three names of the electronic music scene: Richie Hawtin, Carl Cox and Hunter/Game & Pisetzky.`,
      image: GuggenheimImage
    },
    {
      title: 'PRELIOS',
      description: `The Castle Renovation project included renovating and rebranding of one of the last Castle still lived by the same family Della Stufa from 600 Years,
                    after the renovation it started to get rented for various events, and every single renewed Villa’s too, making the castle very sustainably profitable. `,
      image: RenovationImage
    },
  ] as Array<IProject>

  return (
    <div className={styles.projects}>
      <h2>Selected projects</h2>
      <div className="wrapper">
        {Object.values(projects).map((project: IProject, key: number) =>
          <Project image={project.image} title={project.title} description={project.description} key={key} />)}
        {showMore && Object.values(moreProjects).map((project: IProject, key: number) =>
          <Project image={project.image} title={project.title} description={project.description} key={key} />)}
      </div>
      {!showMore &&
        <Button onClick={() => setShowMore(true)} text={'Load more'} icon={ArrowDown2} />
      }
    </div>
  );
}

function Portfolio() {
  return (
    <div className={styles.portfolio}>
      <video className="background-video" autoPlay loop muted poster={PortfolioBG}>
        <source src={VideoExclusive} type="video/mp4" />
      </video>
      <div className="wrapper">
        <h2>Creating the next generation of innovations and new experiences</h2>
        <Button onClick={() => {/* client to provide url */ }} text={'Download our portfolio'} icon={ArrowDownload} />
      </div>
    </div>
  );
}

function Why() {

  const reasons = [
    {
      title: 'Personal relationships with the world\'s top DJs',
      description: `We elevate market-defining brands through
                    comprehensive creative solutions to empower their growth,
                    make great impact and bring magic into the world.`,
      image: DJImage
    },
    {
      title: 'Pioneers in integrating VR into real estate',
      description: `We elevate market-defining brands through comprehensive
                    creative solutions to empower their growth, make great impact and bring magic into the world.`,
      image: PioneersImage
    },
    {
      title: 'Top 1% Metaverse production',
      description: `We elevate market-defining brands through
                    comprehensive creative solutions to empower their growth,
                    make great impact and bring magic into the world.`,
      image: MetaImage
    },
    {
      title: 'Top 1% AI Avatar generation',
      description: `We elevate market-defining brands through
                    comprehensive creative solutions to empower their growth,
                    make great impact and bring magic into the world.`,
      image: AIImage
    },
    {
      title: 'Top-notch music production',
      description: `We elevate market-defining brands through comprehensive
                    creative solutions to empower their growth,
                    make great impact and bring magic into the world.`,
      image: MusicImage
    },
  ] as Array<IReason>

  const [active, setActive] = useState(0);

  const Bullets = () => {
    const swiper = useSwiper();

    return (
      <ul className="bullets">
        {Object.values(reasons).map((reason: IReason, key: number) =>
          <li className={key === active ? 'active' : ''} onClick={() => swiper.slideNext()} key={key}>|</li>)}
      </ul>
    )
  };

  return (
    <div className={styles.why}>
      <div className="wrapper">
        <h2>Why sokail?</h2>
        <div className="slider">
          <Swiper
            onSlideChange={(slide: any) => setActive(slide.realIndex)}
            slidesPerView={1}
            spaceBetween={30}
            effect={'fade'}
            modules={[EffectFade, Autoplay]}
            grabCursor={true}
            speed={1000}
            loop={true}
            autoplay={{
              delay: 5000,
              disableOnInteraction: true,
            }}
          >
            <Bullets />
            {Object.values(reasons).map((reason: IReason, key: number) =>
              <SwiperSlide key={key}>
                <Reason image={reason.image} title={reason.title} description={reason.description} />
              </SwiperSlide>)}
          </Swiper>
        </div>
      </div>
    </div>
  );
}

function Book() {
  const locations = [
    {
      title: 'Castel Del Monte',
      subtitle: 'PUGLIA, LOCATION WHERE GAME OF THRONES WAS FILMED',
      image: CastelDelMonteImage
    },
    {
      title: 'Chiesa Sconsacrata Via Piero Della Francesca',
      subtitle: 'MILANO',
      image: ChiesaImage
    },
    {
      title: 'Castello Visconti Sforza',
      subtitle: 'MILANO',
      image: ViscontiImage
    },
    {
      title: 'Castello del Calcione',
      subtitle: 'FIRENZE',
      image: CalcioneImage
    },
    {
      title: 'Castello di Tolcinasco Pieve Emanuele',
      subtitle: 'MILANO',
      image: EmanuleImage
    },
    {
      title: 'Chiesa San Paolo Converso',
      subtitle: 'MILANO',
      image: ConversoImage
    },
    {
      title: 'Labirinto Della Masone, Parma',
      subtitle: 'PARMA',
      image: LabirintoImage
    },
  ] as Array<ILocation>

  const navigate = useNavigate();
  const [slideIndex, setSlideIndex] = useState(0);

  return (
    <div className={styles.book}>
      <div className="wrapper">
        <h3>With us you have</h3>
        <h2>Access to the most prestigious venues in Italy</h2>
        <p>These are some of the locations we can get you to</p>
        <Button onClick={() => navigate('/contact')} text={'Book now'} icon={ArrowRight} />
      </div>
      <div className="slider">
        <span className="bg-image" style={{ backgroundImage: `url(${locations[slideIndex].image})` }} />
        <Swiper
          navigation={true}
          slidesPerView={2}
          spaceBetween={0}
          modules={[Autoplay, Navigation]}
          grabCursor={true}
          speed={500}
          loop={true}
          centeredSlides={true}
          onSlideChange={(e: any) => setSlideIndex(e.realIndex)}
          autoplay={{
            delay: 5000,
            disableOnInteraction: true,
          }}
        >
          {Object.values(locations).map((location: ILocation, key: number) =>
            <SwiperSlide key={key} style={{ opacity: key === slideIndex ? 1 : 0.6 }}>
              <Location image={location.image} title={location.title} subtitle={location.subtitle} />
            </SwiperSlide>)}
        </Swiper>
        <span className="book-action">
          <Button onClick={() => navigate('/contact')} text={'Book now'} icon={ArrowRight} />
        </span>
      </div>
    </div>
  );
}

function Exclusive() {
  return (
    <div className={styles.exclusive}>
      <div className="wrapper">
        <div className="left-side">
          <h2>Monumental.<br />Exclusive.</h2>
          <div className="categories">
          </div>
          <p>
            We take pride in curating exceptional spaces that transcend the ordinary,
            providing the perfect canvas for any event you envision.
            From intimate gatherings to grand celebrations,
            our handpicked venues stand as testaments to unparalleled luxury and aesthetic grandeur.
          </p>
          <p>
            Immerse yourself in a world where each location tells a unique story,
            where every corner is a masterpiece waiting to host your special moments.
            Elevate your events with our distinctive venues that promise not just an occasion,
            but an unforgettable experience that lingers in the hearts and minds of all who attend.
          </p>
          <ul>
            <li>Festivals</li>
            <li>Private events</li>
            <li>Parties</li>
            <li>Weddings</li>
          </ul>
        </div>
        <div className="right-side">
          <video autoPlay loop muted poster={ExcluBG}>
            <source src={VideoExclusive} type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  );
}

function PartnerUp() {
  const navigate = useNavigate();
  return (
    <div className={styles.partnerUp}>
      <video className="background-video" autoPlay loop muted poster={PartnerUpBG}>
        <source src={VideoPortfolio} type="video/mp4" />
      </video>
      <div className="wrapper">
        <h2>Let’s partner up!</h2>
        <p>
          Ready to take your brand to new heights? We're here to turn your vision into reality. Contact us today and let's empower your brand's success.
        </p>
        <Button onClick={() => navigate('/contact')} text={'Let us take you next level'} icon={ArrowRight} />
      </div>
    </div>
  );
}

export default function Home() {
  return (
    <div className={styles.home}>
      <Into />
      <Into2 />
      <Services />
      <Projects />
      <Portfolio />
      <Why />
      <Book />
      <Exclusive />
      <Partners />
      <PartnerUp />
    </div>
  );
}