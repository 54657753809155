import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import GoogleFonts from 'react-google-font';

import './App.css';
import Home from './pages/home/home.page';
import * as mainStyles from './assets/css/main'
import Footer from './components/footer';
import Contact from './pages/contact/contact.page';
import Services from './pages/services/services.page';
import Header from './components/header';

function App() {
  return (
    <div className="App">
      <GoogleFonts
        fonts={[
          {
            font: 'Inter:200,300,400,500,600,700',
          },
          {
            font: 'Unbounded:200,300,400,500,600,700',
          },
          {
            font: 'Outfit:200,300,400,500,600,700',
          },
        ]} />
      <BrowserRouter>
        <Header />
        <div className={mainStyles.container}>
          <Routes >
            <Route path='/' element={<Home />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/services' element={<Services />} />
          </Routes >
        </div>
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default App;
