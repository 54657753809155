import { css } from "@emotion/css";
import { IProject } from "../interfaces/_main";
import { laptop, mobile, tablet } from "../assets/css/breakpoints";

const project = css({
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flex: '32% 0',
    width: 0,
    marginBottom: 20,

    p: {
        color: '#626262',
        fontFamily: 'Inter',
        fontSize: 16,
        fontStyle: 'normal',
        fontWeight: 300,
        textAlign: 'left',
        margin: 0,
    },
    h4: {
        fontFamily: 'Inter',
        fontSize: 19.24,
        fontStyle: 'normal',
        fontWeight: 500,
        marginBottom: 10,
    },
    img: {
        borderRadius: 34,
        width: '100%'
    },
    '.wrapper': {
        display: 'flex',
        flexWrap: 'wrap',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
    },

    [laptop]: {
        flex: '32% 0',
        width: '100%',
    },
    [tablet]: {
        flex: '48% 0',
        width: '100%',
    },
    [mobile]: {
        flex: '100%',
        width: '100%',
    }
})

export default function Project({ image, title, description }: IProject) {
    return (
        <div className={project}>
            <img alt={title} src={image} />
            {/* <span style={{backgroundImage: `url(${image})`}} /> */}
            <h4>{title}</h4>
            <p>{description}</p>
        </div>
    );
}