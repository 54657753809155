import { useNavigate } from "react-router-dom";

import * as styles from "../services/services.styles";

import Service from "../../components/service";
import Button from "../../components/button";

import EventIMG from '../../assets/images/services/event.jpg'
import MusicIMG from '../../assets/images/services/music.jpg'
import AIIMG from '../../assets/images/services/ai.jpg'
import BrandIMG from '../../assets/images/services/brand.jpg'
import DigitalIMG from '../../assets/images/services/digital.jpg'
import ArrowRight from '../../assets/images/icons/arrow-right.svg';

export default function Services() {
    const navigate = useNavigate();
    const services = [
        {
            title: 'Full service event production',
            description: `We are a well-established industry leader in experience design, event organization and marketing.
                          Having strong relationships with world’s best bands and DJs, and access to VIP locations,
                          we are able to <b>create the most exclusive and bold experiences for our clients.</b>`,
            image: EventIMG,
            items: ['Venues', 'DJ Booking', 'A/V equipment', 'Creative concepts']
        },
        {
            title: 'Sound & Visual',
            description: `We're passionately dedicated to bringing your ideas to life.
                            Our expertise shines in <b>crafting exceptional soundscapes,
                            compelling videos, and visionary architectural concepts,</b>
                            ensuring your projects are marked by excellence and creativity.`,
            image: MusicIMG,
            items: ['Video production', 'Music production', 'Sound design', 'Architectural design & rendering']
        },
        {
            title: 'Metaverse & AI',
            description: `Living in a digital world is a whole new realm,
                            and existing between reality and digital world requires to have a human representation in both.
                            Our team uses <b>latest cutting-edge technology for the digitalization to improve your online presence,
                            foster innovation and enhance user experience.</b>`,
            image: AIIMG,
            items: ['Metaverse production', 'Sound design for NFTs', 'AI avatar generation']
        },
        {
            title: 'Brand development',
            description: `<b>We help brands be their most inspiring selves.</b>
                            Own their quirks - their edge - their culture.
                            Help them be confidently attractive.
                            We can do it all: from logo creation to market placement,
                            with a special focus on the fashion industry.`,
            image: BrandIMG,
            items: ['Visual identity', 'Brand narrative']
        },
        {
            title: 'Online Marketing',
            description: `We go beyond of what is possible in digital realm. By delivering creative and strategic solutions to any digital problem, we can only say:
                            </br></br>
                          Whatever it is, we can make it happen.`,
            image: DigitalIMG,
            items: ['Digital marketing', 'Social media', 'Influencer marketing']
        },
    ]

    return (
        <div className={styles.services}>
            <div className={styles.intro}>
                <div className="wrapper">
                    <h1>
                        driving your vision forward
                    </h1>
                    <p>
                        With a years of extraordinary experience we are empowered to make your vision become a reality.
                        From digital meta-world to immersive real-life experiences the main objective stays always the same: making all the ideas real.
                    </p>
                </div>
            </div>
            <div className={styles.items}>
                {Object.values(services).map((service: any, key: number) =>
                    <Service
                        key={key}
                        title={service.title}
                        description={service.description}
                        image={service.image}
                        items={service.items} />
                )}
            </div>
            <div className={styles.closing}>
                <h2>
                    Empowering  businesses to reach their true potential
                </h2>
                <Button onClick={() => navigate('/contact')} text={'Book now'} icon={ArrowRight} />
            </div>
        </div>
    )
}