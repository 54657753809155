import { css } from "@emotion/css";
import Home from "../home/home.page";
import { useEffect, useState } from "react";
import ExitIcon from "../../assets/images/contact/exit.svg"
import Button from "../../components/button";
import ArrowRight from '../../assets/images/icons/arrow-right.svg';
import { Link } from "react-router-dom";
import { mobile, tablet } from "../../assets/css/breakpoints";

const FORM_ENDPOINT = "https://example.com";

const contact = css({
    zIndex: 999,
    position: 'fixed',
    maxWidth: 756,
    width: '100%',
    right: '-100%',
    top: 0,
    height: '100%',
    borderLeft: '1px solid #464343',
    background: 'rgba(6, 4, 10, 0.78)',
    boxShadow: '-5.50765px 2.75382px 11px 0px rgba(0, 0, 0, 0.58)',
    backdropFilter: 'blur(16.5px)',
    padding: '2% 10% 10% 5%',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    alignContent: 'flex-start',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    transition: 'right 2s ease 0s',

    '&.active': {
        right: 0
    },

    h4: {
        fontFamily: 'Outfit',
        fontSize: 16,
        fontStyle: 'normal',
        fontWeight: 400,
        margin: 0,
        textTransform: 'uppercase',
    },
    h1: {
        fontFamily: 'Unbounded',
        fontSize: 51.217,
        fontStyle: 'normal',
        fontWeight: 400,
        maxWidth: 279,
        marginBottom: 61,
    },

    p: {
        fontFamily: 'Outfit',
        fontSize: 16,
        fontStyle: 'normal',
        fontWeight: 300,
        color: '#8E8D8D',
        textAlign: 'left',
        marginBottom: 37
    },

    '.header': {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        alignContent: 'center',
        alignItems: 'center',
        width: '100%',

        '.exit-link': {
            '.exit': {
                width: 44,
                height: 44,
            },
        },
    },
    '.content': {
        width: '100%',

        form: {
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            alignContent: 'center',
            alignItems: 'flex-start',
            justifyContent: 'center',
            gap: 19,

            '.field': {
                width: '48.5%',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'nowrap',
                alignContent: 'center',
                justifyContent: 'center',
                alignItems: 'center',

                '&.full': {
                    width: '100%',
                },

                '&.button': {
                    width: '100%',
                    marginTop: 30,

                    button: {
                        width: '100%',
                        padding: 24,
                        fontFamily: 'Inter',
                        fontSize: 14,
                    }
                }
            }
        }
    },

    [tablet]: {
        maxWidth: '100%',
        width: '100%',
        top: 'auto',
        padding: 0,

        h1: {
            fontSize: 40,
            maxWidth: '100%',
        },
        '.header': {
            width: '90%',
            margin: 'auto',
        },
        '.content': {
            flexDirection: 'column',
            alignItems: 'stretch',
            gap: 30,
            width: '90%',
            margin: 'auto',

            form: {
                '.field': {
                    width: '100%',
                }
            }
        }
    },
    [mobile]: {
        h1: {
            fontSize: 30,
            marginBottom: 20,
            textAlign: 'left'
        },
    }
})

export default function Contact() {
    const [submitted, setSubmitted] = useState(false);
    const [isActive, setIsActive] = useState(false);

    const handleSubmit = (e: any) => {
        return;
        //TODO

        e.preventDefault();

        const inputs = e.target.elements;
        const data = {} as any;

        for (let i = 0; i < inputs.length; i++) {
            if (inputs[i].name) {
                data[inputs[i].name] = inputs[i].value;
            }
        }

        fetch(FORM_ENDPOINT, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('There was an error');
                }

                setSubmitted(true);
            })
            .catch((err) => {
                // Submit the form manually
                e.target.submit();
            });
    };

    useEffect(() => {
        setIsActive(true)

        return () => {
            setIsActive(false);
        };
    }, []);

    if (submitted) {
        return (
            <>
                <div className="text-2xl">Thank you!</div>
                <div className="text-md">We'll be in touch soon.</div>
            </>
        );
    }

    return (
        <>
            <div className={`${contact} ${isActive ? 'active' : ''}`}>
                <div className="header">
                    <h4 className="title">Contact</h4>
                    <Link to="/" className="exit-link">
                        <img src={ExitIcon} className="exit" alt={'Exit Icon'} />
                    </Link>
                </div>
                {submitted
                    ?
                    <div className="content">
                        <div className="text-2xl">Thank you!</div>
                        <div className="text-md">We'll be in touch soon.</div>
                    </div>
                    :
                    <div className="content">
                        <h1>
                            Let’s get in touch
                        </h1>
                        <p>
                            Enter your message information here
                        </p>
                        <form
                            action={FORM_ENDPOINT}
                            onSubmit={handleSubmit}
                            method="POST"
                        >
                            <div className="field">
                                <input
                                    type="text"
                                    placeholder="Name*"
                                    name="name"
                                    required
                                />
                            </div>
                            <div className="field">
                                <input
                                    type="email"
                                    placeholder="Email Address*"
                                    name="email"
                                    required
                                />
                            </div>
                            <div className="field">
                                <input
                                    type="text"
                                    placeholder="Project Name*"
                                    name="name"
                                    required
                                />
                            </div>
                            <div className="field">
                                <input
                                    type="text"
                                    placeholder="State of Business*"
                                    name="name"
                                    required
                                />
                            </div>
                            <div className="field full">
                                <input
                                    type="text"
                                    placeholder="Message*"
                                    name="name"
                                    required
                                />
                            </div>
                            <div className="field button">
                                <Button onClick={handleSubmit} text={'Let us take you next level'} icon={ArrowRight} style={{ width: '100%' }} />
                            </div>
                        </form>
                    </div>
                }
            </div>
            <Home />
        </>
    );
}