import { css } from "@emotion/css";

import Logo from '../assets/images/logo.svg';
import { laptop, mobile, tablet } from "../assets/css/breakpoints";

const footer = css({
    backgroundColor: 'black',
    padding: '60px 120px',
    color: 'white',


    '.wrapper': {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignContent: 'flex-start',
        justifyContent: 'space-between',
        gap: 30,

        '.left-side': {
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'nowrap',
            justifyContent: 'space-between',
            alignItems: 'flex-start',

            h4: {
                textAlign: 'left',
                fontFamily: 'Unbounded',
                fontSize: 23.697,
                fontStyle: 'normal',
                fontWeight: 300,
                margin: 0,
                marginBottom: 15,
            },
            a: {
                color: '#D9D9D9',
                fontFamily: 'Inter',
                fontSize: 25,
                fontStyle: 'normal',
                fontWeight: 300,
                textDecorationLine: 'underline',
                background: '-webkit-linear-gradient(327deg, #ffffff , #AEB9F5 70%, #ffffff)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
            }
        },
        '.right-side': {
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'nowrap',
            alignItems: 'flex-end',
            justifyContent: 'space-between',

            p: {
                textAlign: 'right',
                fontFamily: 'Inter',
                fontSize: 25,
                fontStyle: 'normal',
                fontWeight: 300,
                margin: 0,
                marginBottom: 15,
            },

            img: {
                maxWidth: 136
            }
        }
    },
    [laptop]: {
        padding: '60px',
    },
    [tablet]: {
        padding: '30px',

        '.wrapper': {
            flexDirection: 'column',

            '.left-side': {
                paddingBottom: 40,
                paddingTop: 40,
                h4: {
                    textAlign: 'center'
                }
            }
        }
    },
    [mobile]: {
        padding: '20px',

        '.wrapper': {

            '.right-side': {
                p: {
                    fontSize: 19,
                },
            },
            '.left-side': {
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'nowrap',
                alignContent: 'center',
                justifyContent: 'center',
                alignItems: 'center',
                paddingBottom: 40,
                paddingTop: 40,

                h4: {
                    fontSize: 19,
                },
                p: {
                    fontSize: 19,
                },
            }
        }
    }
})

export default function Footer() {
    return (
        <footer className={footer}>
            <div className={'wrapper'}>
                <div className={'left-side'}>
                <h4>Meet us and we'll show you what we can do for you</h4>
                <a href="mailto:meet@sokail.media">meet@sokail.media</a>
                </div>
                <div className={'right-side'}>
                    <p>Milan, Miami, New York, Dubai, Berlin</p>
                    <img src={Logo} className="logo" alt={'sokail'} />
                </div>
            </div>
        </footer>
    );
}