import { css } from "@emotion/css";
import PartnersBG from '../../assets/images/home/partners-bg.jpg'
import ArrowSlider from '../../assets/images/home/arrow-slide-right.svg'
import { laptop, mobile, tablet } from "../../assets/css/breakpoints";

const home = css({
    'p, h1, h2, h3, h4, a, li': {
        color: 'white'
    },
    'button, input[type="submit"], input[type="reset"]': {
        color: 'inherit',
        border: 'none',
        font: 'inherit',
        cursor: 'pointer',
        outline: 'inherit',
    },
    '.background-video': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
    },
})

const partnerUp = css({
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    position: 'relative',

    '.wrapper': {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 40,
        maxWidth: 715,
        zIndex: 1,


        h2: {
            textAlign: 'center',
            fontFamily: 'Unbounded',
            fontSize: 88,
            fontStyle: 'normal',
            fontWeight: 400,
            textTransform: 'uppercase',
            margin: 0,
        },
        p: {
            textAlign: 'center',
            fontFamily: 'Inter',
            fontSize: 19,
            fontStyle: 'normal',
            fontWeight: 300,
            lineHeight: 'normal',
            margin: 0,
        },
    },

    [tablet]: {
        padding: 30,
        '.wrapper': {
            flexDirection: 'column',
            height: '100%',

            h2: {
                fontSize: 60,
            }
        }
    },

    [mobile]: {
        padding: 20,
        '.wrapper': {
            h2: {
                fontSize: 40,
            }
        }
    }
})

const exclusive = css({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '120px',
    position: 'relative',
    overflow: 'hidden',

    '&:before': {
        background: 'radial-gradient(circle, rgb(28 77 171) 0%, rgb(0 0 0 / 0%) 50%, rgb(0 0 0 / 0%) 100%)',
        position: 'absolute',
        content: '""',
        width: '100%',
        height: '100%',
        zIndex: 1,
        left: '-50%',
    },

    '.wrapper': {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignContent: 'center',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 50,
        width: '100%',
        position: 'relative',

        '.left-side': {
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'nowrap',
            alignContent: 'center',
            justifyContent: 'center',
            alignItems: 'flex-start ',
            position: 'relative',
            zIndex: 1,
            maxWidth: 700,
            width: '100%',

            h2: {
                fontFamily: 'Unbounded',
                fontSize: '88px',
                fontStyle: 'normal',
                fontWeight: 300,
                lineHeight: 'normal',
                margin: 0,
                marginBottom: 33,
                textAlign: 'left',
            },
            p: {
                fontFamily: 'Inter',
                fontSize: 19,
                fontStyle: 'normal',
                fontWeight: 300,
                lineHeight: 'normal',
                margin: 0,
                marginBottom: 23,
                textAlign: 'left',
            },
            ul: {
                listStyle: 'none',
                margin: 0,
                padding: 0,
                li: {
                    fontFamily: 'Unbounded',
                    fontSize: 36,
                    fontStyle: 'normal',
                    fontWeight: 300,
                    marginBottom: 10,
                    textAlign: 'left',
                    background: '-webkit-linear-gradient(327deg, #ffffff , #AEB9F5 70%, #ffffff)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    border: '1px solid',
                    borderImageSlice: 1,
                    borderImageSource: 'linear-gradient(to left, #ffffff00, #ffffff)',
                    borderLeft: 0,
                    borderRight: 0,
                    borderTop: 0,
                    paddingTop: 10,
                    paddingBottom: 10
                }
            },
        },
        '.right-side': {
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'nowrap',
            alignContent: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            maxWidth: 700,
            width: '100%',

            video: {
                height: 900,
                width: 900,
                objectFit: 'cover',
                position: 'absolute',
            }
        },
        '.categories': {
            marginBottom: 20,
        }
    },

    [laptop]: {
        padding: '120px 60px',
    },
    [tablet]: {
        padding: '50px 30px',
        height: '100%',
        display: 'block',
        marginTop: 0,

        '.wrapper': {
            flexDirection: 'column',
            height: '100%',
            '.left-side': {

                h2: {
                    fontSize: 60,
                },
                ul: {
                    li: {
                        fontSize: 40,
                    }
                }
            }
        }
    },

    [mobile]: {
        height: 'auto',
        padding: '10px 10px',
        gap: 10,
        display: 'block',
        marginBottom: 20,
        '.wrapper': {
            display: 'block',
            '.left-side': {
                h2: {
                    fontSize: 40,
                },

                ul: {
                    li: {
                        fontSize: 26,
                    }
                }
            },

            '.right-side': {
                top: 0,
                position: 'absolute',

                video: {
                    height: 500,
                    width: 500,
                    objectFit: 'cover',
                    position: 'absolute',
                    top: 0,
                    right: '-35%',
                }
            },

            '.categories': {
                marginBottom: 220,
            }
        },
    }
})

const book = css({
    flexDirection: 'column',
    flexWrap: 'nowrap',
    alignContent: 'flex-start',
    alignItems: 'center',
    justifyContent: 'center',

    '.wrapper': {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        padding: '60px 120px',

        h3: {
            textAlign: 'right',
            fontFamily: 'Unbounded',
            fontSize: 19,
            fontStyle: 'normal',
            fontWeight: 300,
            lineHeight: 'normal',
            margin: 0,
            marginBottom: 15,
        },
        h2: {
            textAlign: 'center',
            fontFamily: 'Unbounded',
            fontSize: '70px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: 'normal',
            margin: 0,
            marginBottom: 30,
            maxWidth: 1100,
            background: '-webkit-linear-gradient(327deg, #ffffff , #AEB9F5 70%, #ffffff)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
        },
        p: {
            textAlign: 'center',
            fontFamily: 'Inter',
            fontSize: 19,
            fontStyle: 'normal',
            fontWeight: 300,
            lineHeight: 'normal',
            margin: 0,
            marginBottom: 55,
        },
    },

    '.slider': {
        position: 'relative',
        height: '90vh',
        width: '100%',

        ':after': {
            position: 'absolute',
            bottom: 0,
            content: "''",
            background: 'linear-gradient(to bottom, transparent 0%, black 100%)',
            width: '100%',
            height: 500,
            zIndex: 0,
            left: 0
        },
        ':before': {
            position: 'absolute',
            top: 0,
            content: "''",
            background: 'linear-gradient(to top, transparent 0%, black 100%)',
            width: '100%',
            height: 500,
            zIndex: 1,
            left: 0
        },

        '.bg-image': {
            width: '100%',
            backgroundPosition: 'center',
            height: '90vh',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            position: 'absolute',
            transition: 'background-image 1.5s ease-in-out',
            left: 0,
            top: 0,
        },

        '.book-action': {
            position: 'absolute',
            bottom: 50,
            left: 0,
            right: 0,
            margin: 'auto',
            maxWidth: 140,
            background: 'transparent',
            zIndex: 1
        },

        '.swiper': {
            width: '100%',
            overflow: 'hidden',
            display: 'flex',
            position: 'relative',

            '.swiper-wrapper': {
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'nowrap',
                alignContent: 'center',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                width: 'auto',

                '.swiper-slide': {
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                    alignContent: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginRight: '0px !important'
                },
            },
            '.swiper-button-prev, .swiper-button-next': {
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'right',
                backgroundSize: 'unset',
                cursor: 'pointer',
                display: 'block',
                position: 'absolute',
                zIndex: 1
            },

            '.swiper-button-prev': {
                backgroundImage: `url(${ArrowSlider});`,
                transform: 'rotate(180deg)',
                bottom: 140,
                marginLeft: 60,
                width: 100,
                height: 100,
                left: 0,
            },

            '.swiper-button-next': {
                backgroundImage: `url(${ArrowSlider});`,
                bottom: 140,
                marginRight: 60,
                width: 100,
                height: 100,
                right: 0,
            }
        }
    },

    [laptop]: {
        '.wrapper': {
            padding: 60,
        }
    },

    [tablet]: {
        height: '100%',
        '.wrapper': {
            padding: 30,
            h2: {
                fontSize: 50,
            },
        }
    },
    [mobile]: {
        '.wrapper': {
            padding: 20,
            h2: {
                fontSize: 38,
            },
        }
    }
})

const why = css({
    padding: '60px 120px',
    marginBottom: 40,

    '.wrapper': {
        h2: {
            fontFamily: 'Unbounded',
            fontSize: 54,
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: 'normal',
            textAlign: 'left',
            maxWidth: 850,
            width: '100%',
        },
        '.slider': {
            position: 'relative',
            '.bullets': {
                position: 'absolute',
                left: 0,
                top: 0,
                zIndex: 1,
                height: '100%',
            },
            '.swiper': {
                width: '100%',
                height: '100%',

                '.swiper-slide': {
                    backgroundColor: 'black',

                }
            },

            ul: {
                listStyle: 'none',
                padding: 0,
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'nowrap',
                alignContent: 'center',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 5,

                li: {
                    fontFamily: 'Outfit',
                    fontSize: 28,
                    fontWeight: 600,
                    cursor: 'pointer',
                    color: '#848484',

                    '&.active': {
                        color: 'white'
                    }
                }
            }
        },
    },
    [laptop]: {
        padding: '60px',
        marginBottom: 0,
        '.wrapper': {
            '.slider': {
                '.swiper': {
                    width: 'auto',
                    height: '100%',

                    '.swiper-slide': {
                        backgroundColor: 'black',
                    }
                },
            }
        }
    },
    [tablet]: {
        height: '100%',
        padding: 30,

        '.wrapper': {
            h2: {
                fontSize: 40,
            },
            '.slider': {
                '.bullets': {
                    justifyContent: 'flex-start'
                }
            }
        }
    },
    [mobile]: {
        padding: 20,
        '.wrapper': {
            '.slider': {
                '.bullets': {
                    display: 'none',
                }
            }
        }
    }
})

const portfolio = css({
    height: '100vh',
    backgroundColor: 'black',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    alignContent: 'flex-start',
    alignItems: 'flex-start',
    justifyContent: 'center',
    position: 'relative',

    '.background-video': {
        width: '60%',
        height: '60%',
        top: '20%',
        marginLeft: 'auto',
    },

    '.wrapper': {
        maxWidth: 1400,
        marginLeft: '16vh',
        zIndex: 1,

        h2: {
            fontFamily: 'Unbounded',
            fontSize: 88,
            fontStyle: 'normal',
            fontWeight: 400,
            letterSpacing: '-0.088px',
            maxWidth: 850,
            textAlign: 'left',
            width: '100%',
            background: '-webkit-linear-gradient(327deg, #ffffff , #AEB9F5 70%, #ffffff)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
        },
    },

    [tablet]: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        alignContent: 'flex-start',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 30,

        '.background-video': {
            width: '70%',
            height: '70%',
            top: 0,
            marginLeft: 60,
            marginRight: 'auto',
            position: 'relative',
            marginBottom: -120,
        },
        '.wrapper': {
            marginLeft: 0,
            h2: {
                fontSize: 60,
            },
        }
    },
    [mobile]: {
        padding: 20,

        '.background-video': {
            width: '100%',
            height: '50%',
            top: 0,
            position: 'relative',
            marginBottom: '-60px',
            marginLeft: 'auto',
            marginRight: 'auto',
            objectFit: 'cover',

        },
        '.wrapper': {
            h2: {
                fontSize: 33,
            },
        }
    }
})

const projects = css({
    backgroundColor: 'black',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '60px',

    '.wrapper': {
        display: 'flex',
        flexWrap: 'wrap',
        alignContent: 'flex-start',
        justifyContent: 'center',
        alignItems: 'flex-start',
        width: '100%',
        gap: 20,
        marginBottom: 20,
    },
    h2: {
        fontFamily: 'Unbounded',
        fontSize: '70px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        marginTop: 20,
        marginBottom: 80,
        background: '-webkit-linear-gradient(327deg, #ffffff , #AEB9F5 70%, #ffffff)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
    },

    [tablet]: {
        padding: '40px',

        h2: {
            fontSize: '50px',
            marginBottom: 60,
        },
    },

    [mobile]: {
        padding: '20px',

        h2: {
            fontSize: '40px',
            marginBottom: 60,
        },
    }
})

const servicesBottom = css({
    height: '100%',
    width: '100%',
    zIndex: 0,
    marginBottom: '-30%'
})

const services = css({
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    padding: '60px 120px',
    zIndex: 1,


    '.title-wrapper': {
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        alignContent: 'center',
        justifyContent: 'flex-end',
        alignItems: 'center',
        top: 0,

        h4: {
            textAlign: 'center',
            fontFamily: 'Unbounded',
            fontSize: 54,
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: 'normal',

            img: {
                height: 22,
            }
        },
    },
    '.services-wrapper': {
        position: 'relative',
        width: '100%',
        overflow: 'hdden',

        ':after': {
            position: 'absolute',
            bottom: 0,
            content: "''",
            background: 'linear-gradient(to bottom, transparent 0%, black 100%)',
            width: '100%',
            height: 120,
            zIndex: 2,
            left: 0
        },
        ':before': {
            position: 'absolute',
            top: 0,
            content: "''",
            background: 'linear-gradient(to top, transparent 0%, black 100%)',
            width: '100%',
            height: 120,
            zIndex: 2,
            left: 0
        },

        '.swiper-wrapper': {
            width: '100%',
            listStyle: 'none',
            padding: 0,
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'nowrap',
            alignContent: 'center',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: 15,
            position: 'relative',
            height: 515,

            '.swiper-slide': {
                height: 'auto !important',
            },

            '.slide-content': {
                color: '#FFF',
                textAlign: 'center',
                fontFamily: 'Unbounded',
                fontSize: 68,
                fontStyle: 'normal',
                fontWeight: 250,
                lineHeight: 'normal',
                transition: '0.05s',
                cursor: 'pointer',
                background: '-webkit-linear-gradient(327deg, #ffffff , #AEB9F5 70%, #ffffff)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                marginBottom: 15,
                transform: 'scale(0.7)',

                '&.active': {
                    color: '#FFF',
                    textAlign: 'center',
                    background: '-webkit-linear-gradient(327deg, #ffffff , #ffffff 70%, #ffffff)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    textShadow: '0px 0px 50px #756cd5, 0px 0px 50px #756ec9, 0px 0px 50px #4c72ff, 0px 0px 60px rgba(80, 52, 253, 0.50)',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    transform: 'scale(1.2)',
                },
                '&.near': {
                    transform: 'scale(1)',
                }
            },

            '.slide-stripe': {
                backgroundImage: 'linear-gradient(to right, #000000, #2e2e2e, #585858, #878787, #b9b9b9, #b9b9b9, #b9b9b9, #b9b9b9, #878787, #585858, #2e2e2e, #000000)',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                width: '100%',
                height: 1,
                display: 'block',
            }
        },
    },

    [laptop]: {
        padding: '60px',

        '.services-wrapper': {
            '.swiper-wrapper': {
                '.sliper-slide': {
                    maxWidth: '90%',
                },
                '.slide-content': {
                    fontSize: 56,
                }
            }
        }
    },
    [tablet]: {
        padding: '30px',
        height: '100%',

        '.title-wrapper': {
            h4: {
                fontSize: 40,
            },
        },
        '.services-wrapper': {
            '.swiper-wrapper': {
                '.slide-content': {
                    fontSize: 48,
                }
            }
        }
    },
    [mobile]: {
        padding: '20px',
        '.title-wrapper': {
            h4: {
                fontSize: 30,
            },
        },
        '.services-wrapper': {
            '.swiper-wrapper': {
                '.slide-content': {
                    fontSize: 28,
                }
            }
        }
    },
})

const partners = css({
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'stretch',
    backgroundColor: 'black',
    position: 'relative',
    borderRadius: '0px 0px 162px 162px',

    '&:before': {
        background: `url(${PartnersBG})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'top',
        position: 'absolute',
        content: '""',
        width: '100%',
        height: '100%',
        zIndex: 0,
        left: 0,
        top: '-40%'
    },
    '&:after': {
        background: 'linear-gradient(to bottom, rgb(255 255 255 / 0%) 0%, #2E1E6D, rgb(255 255 255 / 0%))',
        position: 'absolute',
        content: '""',
        width: '100%',
        height: '70%',
        zIndex: 0,
        left: 0,
        bottom: '-10%'
    },

    '.section-top': {
        height: 500,
        padding: '0 120px',
        borderRadius: '0px 0px 60px 60px',
        backgroundBlendMode: 'screen, normal, lighten, screen, normal',
        position: 'relative',
        zIndex: 1,

        h3: {
            fontFamily: 'Unbounded',
            textAlign: 'left',
            maxWidth: 850,
            fontSize: '54px',
            paddingTop: 0,
            fontWeight: 400,
            margin: 0,
            marginBottom: 35,
            marginTop: '5vh',
            marginRight: 'auto',
            zIndex: 1,
            position: 'relative'
        },
    },

    '.section-bottom': {
        padding: '0px 120px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        alignContent: 'center',
        paddingBottom: 80,
        position: 'relative',
        zIndex: 1,

        h3: {
            textAlign: 'center',
            width: '100%',
            paddingTop: 0,
            fontFamily: 'Unbounded',
            fontSize: 30,
            fontStyle: 'normal',
            fontWeight: 300,
            margin: 0,
            marginBottom: 85,
        },

        '.partners': {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignContent: 'center',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '50px 30px',
            width: '100%',
        }
    },
    [laptop]: {
        '.section-bottom': {
            padding: '60px 60px',
        }
    },
    [tablet]: {
        padding: '30px',
        '&:after': {
            height: '90%',
        },
        '&:before': {
            left: 0,
            top: '-5%'
        },
        '.section-top': {
            padding: '600 20px',
            h3: {
                fontSize: 40
            }
        },
        '.section-bottom': {
            borderRadius: '0px 0px 50px 50px',
            padding: '60px 20px',
            paddingBottom: 60,
        }
    },
    [mobile]: {
        '.partners:': {
            gap: "10px 10px",
            fontSize: 10,
            margin: 0,
            padding: 0,
        },

        // padding: '20px',
        // '&:after': {
        //     height: '100%',
        // },
        // '&:before': {
        //     left: 0,
        //     top: 0
        // },
        '.section-top': {
            h3: {
                fontSize: 30,
            }
        },
        '.section-bottom': {
            padding: 20,
            h3: {
                fontSize: 26
            },

            '.partners': {
                justifyContent: 'flex-center',
                gap: 25,
            }
        }
    }
})

const intro2 = css({
    backgroundColor: 'flex',
    backgroundPosition: 'center',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 120px',
    zIndex: 1,

    h2: {
        width: '100%',
        maxWidth: 800,
        fontSize: '88px',
        paddingTop: 0,
        marginBottom: 40,
        textAlign: 'center',
        fontFamily: 'Unbounded',
        fontStyle: 'normal',
        fontWeight: 400,
        background: '-webkit-linear-gradient(0deg, #ffffff 0%, #afd0e1 100%)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        zIndex: 1,
    },

    p: {
        width: '100%',
        maxWidth: 755,
        paddingTop: 0,
        fontWeight: 300,
        marginLeft: 20,
        textAlign: 'center',
        fontFamily: 'Inter',
        fontSize: 19,
        fontStyle: 'normal',
        zIndex: 1,
    },
    button: {
        zIndex: 1,
    },
    '.space': {
        marginBottom: 30
    },

    [laptop]: {
        padding: '0 60px',
    },
    [tablet]: {
        h2: {
            fontSize: 60
        }
    },
    [mobile]: {
        padding: '0 20px',

        h2: {
            fontSize: 40
        },
        p: {
            fontSize: 16
        }
    }
});

const intro = css({
    width: '100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'flex-start',

    ':after': {
        position: 'absolute',
        bottom: 0,
        content: "''",
        background: 'linear-gradient(to bottom, transparent 0%, black 100%)',
        width: '100%',
        height: 180,
        zIndex: 2,
        left: 0
    },

    h3: {
        fontFamily: 'Unbounded',
        textAlign: 'left',
        width: '100%',
        maxWidth: 850,
        fontSize: 54,
        paddingTop: 0,
        fontWeight: 400,
        margin: 0,
        marginBottom: 35
    },

    p: {
        fontFamily: 'Inter',
        textAlign: 'left',
        fontSize: '19px',
        marginBottom: 40,
    },
    img: {
        width: '100%',
        maxWidth: 800,
    },

    '.wrapper': {
        borderRadius: 20,
        maxWidth: 760,
        width: '100%',
        padding: '40px 20px',
        position: 'relative',
        overflow: 'hidden',
        border: '1px solid rgba(255, 255, 255, 0.18)',
        background: `url(''), lightgray 50% / cover no-repeat, radial-gradient(185.11% 101.71% at 93.95% 100%, rgba(0, 0, 0, 0.38) 0%, rgba(0, 0, 0, 0.00) 100%), rgba(8, 8, 14, 0.56)`,
        backdropFilter: 'blur(15.5px)',
        zIndex: 1,
        // backgroundColor: 'rgb(0 0 0 / 63%)',

        a: {
            textAlign: 'left',
            display: 'block',
            img: {
                width: 'auto !important',
            },
        }
    },

    '.section-right': {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '-4vh',
        marginLeft: '-17vh',
        zIndex: 1,
    },

    '.section-left': {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        zIndex: 1,
    },
    '.logo': {
        marginTop: 100,
    },
    '.menu': {
        display: 'flex',
        alignItems: 'flex-start',
        gap: 62,
        marginTop: 32,

        a: {
            fontFamily: 'Outfit',
            fontSize: 16,
            fontStyle: 'normal',
            fontWeight: 400,
            textDecoration: 'none',
            color: 'white',
            textTransform: 'uppercase'
        }
    },

    [laptop]: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'stretch',

        '.section-right': {
            marginTop: '0',
            marginLeft: 'auto',
            marginRight: 60,

            h3: {
                fontSize: 50,
                fontStyle: 'normal',
                fontWeight: 400,
            }
        },
        '.section-left': {
            margin: '0px 60px',

            img: {
                maxWidth: 488
            }
        }
    },

    [tablet]: {
        '.section-right': {
            margin: '0px 60px',
        },
        '.section-left': {
            margin: '0px 60px',
        }
    },

    [mobile]: {
        height: '100vh',
        backgroundPosition: '10%',

        '.section-right': {
            margin: '0px 40px',
            height: 'auto',


            h3: {
                fontSize: 30,
                marginBottom: 20
            },
            p: {
                fontSize: 16,
                marginBottom: 20
            }
        },
        '.section-left': {
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'nowrap',
            alignContent: 'center',
            justifyContent: 'flex-end',
            alignItems: 'center',
            margin: '40px 20px',
            height: 'auto',

            img: {
                maxWidth: 488,
                marginTop: 0
            }
        }
    },
})

export {
    home,
    intro,
    intro2,
    partners,
    services,
    servicesBottom,
    projects,
    portfolio,
    why,
    book,
    exclusive,
    partnerUp
}